import React, { useRef } from 'react';
import LoadingButon from '../LoadingButton';
import { Translate } from 'react-auto-translate/lib/commonjs';
import Tree from './Tree.js';
import './familyMember.css'

export default function FamilyTree({ honoree }) {
    const modalRef = useRef();

    const openFamTree = () => {
        return modalRef.current.showModal();
    }

    return (
        <>
            <LoadingButon onClick={openFamTree} title={<Translate>Family Members</Translate>} />
            {
                 typeof honoree !== 'undefined' && Object.keys(honoree).length ? <Tree modalRef={modalRef} honoree={honoree}/> :null
            }
        </>
    )
}