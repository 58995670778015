import React from 'react';
import { Field } from 'react-final-form';
import TextField from '../../../Components/Form/TextField';

const SocialInfoSection = ({ t, readOnly }) => (
  <>
    <div className="form-row">
      <div className="col">
        <Field component={TextField}
          name="Linkedin"
          label="LinkedIn"
          readOnly={readOnly}
        />
      </div>
      <div className="col">
        <Field component={TextField}
          name="Facebook"
          label="Facebook"
          readOnly={readOnly}
        />
      </div>
    </div>
    <div className="form-row">
      <div className="col">
        <Field component={TextField}
          name="Twitter"
          label="Twitter"
          readOnly={readOnly}
        />
      </div>
      <div className="col">
        <Field component={TextField}
          name="Instagram"
          label="Instagram"
          readOnly={readOnly}
        />
      </div>
    </div>
  </>
);

export default SocialInfoSection;