import React from 'react';
import FamilyMember from './FamilyMember';
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function Tree({ modalRef, honoree }) {
    const renderFamilySection = (title, members) => {
        if (!members || (Array.isArray(members) && members.length === 0)) return null;
        if (!Array.isArray(members) && (!members.Name || members.Name.trim() === '')) return null;

        if (Array.isArray(members)) { 
            return (
                <div className="family-section">
                    <div className="section-header">
                        <i className="ti-users section-icon"></i>
                        <h3 className="section-title">{title}</h3>
                    </div>
                    <div className="members-grid">
                        {members.map((member, index) => (
                            <FamilyMember
                                key={index}
                                member={member}
                                relationshipType={title}
                            />
                        ))}
                    </div>
                </div>
            );
        }

        return (
            <div className="family-section">
                <div className="section-header">
                    <i className="ti-user section-icon"></i>
                    <h3 className="section-title">{title}</h3>
                </div>
                <div className="members-grid">
                    <FamilyMember
                        member={members}
                        relationshipType={title}
                    />
                </div>
            </div>
        );
    };

    return (
        <dialog ref={modalRef} className="famTreeModal">
            <div className="modal-header">
                <h2 className="modal-title"><Translate>Family Members</Translate></h2>
                <button
                    className="close-button"
                    onClick={() => modalRef.current.close()}
                >
                    <i className="ti-close"></i>
                </button>
            </div>

            <div className="modal-content">
                <div className="family-tree-container">
                    <div className="tree-layout">
                        {/* Great Grandparents Generation */}
                        <div className="generation-section great-grandparents">
                            {renderFamilySection("Great Grandparents", honoree?.FamilyMembers?.GreatGrandParents)}
                        </div>

                        {/* Grandparents Generation */}
                        <div className="generation-section grandparents">
                            {renderFamilySection("Grandparents", [
                                honoree?.FamilyMembers?.GrandFather,
                                honoree?.FamilyMembers?.GrandMother
                            ].filter(Boolean))}
                        </div>

                        {/* Parents Generation with Aunts & Uncles */}
                        <div className="generation-section parents">
                            <div className="parents-group">
                                {renderFamilySection("Parents", [
                                    honoree?.FamilyMembers?.Father,
                                    honoree?.FamilyMembers?.Mother
                                ].filter(Boolean))}
                            </div>
                            <div className="relatives-group">
                                {renderFamilySection("Uncles", honoree?.FamilyMembers?.Uncles)}
                                {renderFamilySection("Aunts", honoree?.FamilyMembers?.Aunts)}
                            </div>
                        </div>

                        {/* Honoree's Generation */}
                        <div className="generation-section honoree-level">
                            <div className="siblings-spouse-group">
                                {renderFamilySection("Siblings", honoree?.FamilyMembers?.Siblings)}
                                {renderFamilySection("Spouse", honoree?.FamilyMembers?.Spouse)}
                            </div>
                            <div className="cousins-group">
                                {renderFamilySection("Cousins", honoree?.FamilyMembers?.Cousins)}
                            </div>
                        </div>

                        {/* Children Generation */}
                        <div className="generation-section children">
                            {renderFamilySection("Children", honoree?.FamilyMembers?.Childrens)}
                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    );
}
// import React from 'react';
// import FamilyMember from './FamilyMember';
// import { Translate } from 'react-auto-translate/lib/commonjs';

// export default function Tree({ modalRef, honoree }) {
//     const renderFamilySection = (title, members) => {
//         if (!members) return null;

//         if (Array.isArray(members)) {
//             return (
//                 <div className="family-section">
//                     <div className="section-header">
//                         <i className="ti-users section-icon"></i>
//                         <h3 className="section-title">{title}</h3>
//                     </div>
//                     <div className="members-grid">
//                         {members.map((member, index) => (
//                             <FamilyMember
//                                 key={index}
//                                 member={member}
//                                 relationshipType={title}
//                             />
//                         ))}
//                     </div>
//                 </div>
//             );
//         }

//         return (
//             <div className="family-section">
//                 <div className="section-header">
//                     <i className="ti-user section-icon"></i>
//                     <h3 className="section-title">{title}</h3>
//                 </div>
//                 <div className="members-grid">
//                     <FamilyMember
//                         member={members}
//                         relationshipType={title}
//                     />
//                 </div>
//             </div>
//         );
//     };

//     return (
//         <dialog ref={modalRef} className="famTreeModal">
//             <div className="modal-header">
//                 <h2 className="modal-title"><Translate>Family Members</Translate></h2>
//                 <button
//                     className="close-button"
//                     onClick={() => modalRef.current.close()}
//                 >
//                     <i className="ti-close"></i>
//                 </button>
//             </div>

//             <div className="modal-content">
//                 <div className="family-tree-container">
//                     <div className="tree-layout">
//                         {/* Grandparents Generation */}
//                         <div className="generation-section grandparents">
//                             {renderFamilySection("Grandparents", [
//                                 honoree?.FamilyMembers?.GrandFather,
//                                 honoree?.FamilyMembers?.GrandMother
//                             ].filter(Boolean))}
//                         </div>

//                         {/* Parents Generation */}
//                         <div className="generation-section parents">
//                             <div className="parents-group">
//                                 {renderFamilySection("Parents", [
//                                     honoree?.FamilyMembers?.Father,
//                                     honoree?.FamilyMembers?.Mother
//                                 ].filter(Boolean))}
//                             </div>
//                             <div className="parents-siblings">
//                                 {renderFamilySection("Aunts & Uncles", [
//                                     honoree?.FamilyMembers?.Uncle,
//                                     honoree?.FamilyMembers?.Aunt
//                                 ].filter(Boolean))}
//                             </div>
//                         </div>

//                         {/* Honoree and Spouse */}
//                         <div className="generation-section spouse">
//                             {renderFamilySection("Spouse", honoree?.FamilyMembers?.Spouse)}
//                         </div>

//                         {/* Children */}
//                         <div className="generation-section children">
//                             {renderFamilySection("Children", honoree?.FamilyMembers?.Childrens)}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </dialog>
//     );
// }