import React, { useState } from 'react';
import { Field } from 'react-final-form';
import TextField from '../../../Components/Form/TextField';
import SelectField from '../../../Components/Form/Select';

const FamilyMembersSection = ({ t, readOnly, values, form }) => {
  const [selectedRelation, setSelectedRelation] = useState('');
  
  const genderOptions = [
    { id: 'male', name: 'Male' },
    { id: 'female', name: 'Female' }
  ];

  const relationOptions = [
    { id: 'spouse', name: 'Spouse' },
    { id: 'child', name: 'Child' },
    { id: 'parent', name: 'Parent' },
    { id: 'sibling', name: 'Brother/Sister' },
    { id: 'uncle_aunt', name: 'Uncle/Aunt' },
    { id: 'cousin', name: 'Cousin' },
    { id: 'grandparent', name: 'Grandparent' },
    { id: 'great_grandparent', name: 'Great-Grandparent' }
  ];

  const handleAddMember = () => {
    if (!selectedRelation) return;

    const currentMembers = values.FamilyMembers || {};
    let newMembers = { ...currentMembers };

    switch (selectedRelation) {
      case 'spouse':
        if (!newMembers.Spouse) {
          newMembers.Spouse = { Name: '', Gender: values.Gender === 'Male' ? 'female' : 'male' };
        }
        break;
      case 'child':
        if (!newMembers.Childrens) newMembers.Childrens = [];
        newMembers.Childrens.push({ Name: '', Gender: '' });
        break;
      case 'parent':
        if (!newMembers.Mother || !newMembers.Father) {
          const parentGender = !newMembers.Mother ? 'female' : 'male';
          const parentType = !newMembers.Mother ? 'Mother' : 'Father';
          newMembers[parentType] = { Name: '', Gender: parentGender };
        }
        break;
      case 'sibling':
        if (!newMembers.Siblings) newMembers.Siblings = [];
        newMembers.Siblings.push({ Name: '', Gender: '' });
        break;
      case 'uncle_aunt':
        if (!newMembers.Uncles) newMembers.Uncles = [];
        if (!newMembers.Aunts) newMembers.Aunts = [];
        break;
      case 'cousin':
        if (!newMembers.Cousins) newMembers.Cousins = [];
        newMembers.Cousins.push({ Name: '', Gender: '' });
        break;
      case 'grandparent':
        if (!newMembers.GrandMother) {
          newMembers.GrandMother = { Name: '', Gender: 'female' };
        } else if (!newMembers.GrandFather) {
          newMembers.GrandFather = { Name: '', Gender: 'male' };
        }
        break;
      case 'great_grandparent':
        if (!newMembers.GreatGrandParents) newMembers.GreatGrandParents = [];
        newMembers.GreatGrandParents.push({ Name: '', Gender: '' });
        break;
    }

    form.mutators.setValue('FamilyMembers', newMembers);
    setSelectedRelation('');
  };

  const removeMember = (type, index) => {
    const currentMembers = { ...values.FamilyMembers };
    
    if (Array.isArray(currentMembers[type])) {
      currentMembers[type] = currentMembers[type].filter((_, idx) => idx !== index);
    } else {
      delete currentMembers[type];
    }

    form.mutators.setValue('FamilyMembers', currentMembers);
  };

  const renderMemberFields = (type, label, members, isArray = false) => {
    // Return null if members is null/undefined or an empty array
    if (!members || (Array.isArray(members) && members.length === 0)) return null;
    
    // For non-array members, check if it has a Name property and if it's empty
    if (!isArray && (!members.Name || members.Name.trim() === '')) return null;

    const memberList = isArray ? members : [members];
    
    return (
      <div className="member-group">
        <div className="group-header">
          <i className="ti-user"></i>
          <h6>{t(label)}</h6>
        </div>
        <div className="member-fields">
          {memberList.map((member, index) => (
            <div key={isArray ? index : type} className="family-member-field">
              <Field
                component={TextField}
                name={isArray ? `FamilyMembers.${type}[${index}].Name` : `FamilyMembers.${type}.Name`}
                label={`${t(label)} ${isArray ? index + 1 : ''}`}
                readOnly={readOnly}
              />
              <Field
                component={SelectField}
                name={isArray ? `FamilyMembers.${type}[${index}].Gender` : `FamilyMembers.${type}.Gender`}
                label={t('Gender')}
                options={genderOptions}
                readOnly={readOnly}
              />
              {!readOnly && (
                <button
                  type="button"
                  className="remove-member-btn"
                  onClick={() => removeMember(type, index)}
                >
                  <i className="ti-close"></i>
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const { FamilyMembers = {} } = values;

  return (
    <div className="family-members-section">
      <div className="section-header">
        <div className="section-title-wrapper">
          <i className="ti-users"></i>
          <h5 className="section-title">{t('Family Members')}</h5>
        </div>
        {!readOnly && (
          <div className="add-member-controls">
            <select
              className="form-select"
              value={selectedRelation}
              onChange={e => setSelectedRelation(e.target.value)}
            >
              <option value="">{t('Select Relation')}</option>
              {relationOptions.map(option => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))
            }
            </select>
            <button
              type="button"
              className="add-member-btn"
              onClick={handleAddMember}
              disabled={!selectedRelation}
            >
              <i className="ti-plus"></i>
              <span>{t('Add Family Member')}</span>
            </button>
          </div>
        )}
      </div>

      <div className="members-container">
        {renderMemberFields('GreatGrandParents', 'Great Grandparent', FamilyMembers.GreatGrandParents, true)}
        {renderMemberFields('GrandMother', 'Grandmother', FamilyMembers.GrandMother)}
        {renderMemberFields('GrandFather', 'Grandfather', FamilyMembers.GrandFather)}
        {renderMemberFields('Mother', 'Mother', FamilyMembers.Mother)}
        {renderMemberFields('Father', 'Father', FamilyMembers.Father)}
        {renderMemberFields('Spouse', 'Spouse', FamilyMembers.Spouse)}
        {renderMemberFields('Siblings', 'Sibling', FamilyMembers.Siblings, true)}
        {renderMemberFields('Childrens', 'Child', FamilyMembers.Childrens, true)}
        {renderMemberFields('Uncles', 'Uncle', FamilyMembers.Uncles, true)}
        {renderMemberFields('Aunts', 'Aunt', FamilyMembers.Aunts, true)}
        {renderMemberFields('Cousins', 'Cousin', FamilyMembers.Cousins, true)}
      </div>
    </div>
  );
};

export default FamilyMembersSection;