import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { useTranslation } from "react-i18next";
import { request } from '../../Services/networkService';
import { usePopupContext } from '../../Contexts/popupContext';
import LoadingButton from '../../Components/LoadingButton';
import { formatDate } from '../../Util/common';
import './sections/FamilyMembersSection.css';

// Only components used directly in PersonalInfo
import TextField from '../../Components/Form/TextField';
import TextArea from '../../Components/Form/TextArea';
import CityStateCountry from '../../Components/Form/CityStateCountry';

// Section Components
import BasicInfoSection from './sections/BasicInfoSection';
import SocialInfoSection from './sections/SocialInfoSection';
import FamilyMembersSection from './sections/FamilyMembersSection';
import Gallery from '../../Components/Gallery';
import FamilyTree from '../../Components/Family_Tree';

export default function PersonalInfo({ user, update, readOnly = false }) {
    const { handleError } = usePopupContext();
    const { t } = useTranslation('common');
    const history = useHistory();
    const [inputFields, setInputFields] = useState([]);
    const infoRef = useRef();

    const addInputField = () => {
        if (inputFields.length < 5) {
            setInputFields([...inputFields, { Name: '', Gender: '' }]);
        }
    };

    const removeInputFields = (index) => {
        setInputFields(inputFields.filter((_, idx) => idx !== index));
    };

    const onSubmit = async (data) => {
        try {
            // Ensure children are only in FamilyMembers.Childrens
            if (data.Childrens) {
                delete data.Childrens;
            }

            // Set default genders if not already set
            const familyMembers = data.FamilyMembers || {};
            if (familyMembers.GrandMother?.Name && !familyMembers.GrandMother.Gender) {
                familyMembers.GrandMother.Gender = 'female';
            }
            if (familyMembers.GrandFather?.Name && !familyMembers.GrandFather.Gender) {
                familyMembers.GrandFather.Gender = 'male';
            }
            if (familyMembers.Uncle?.Name && !familyMembers.Uncle.Gender) {
                familyMembers.Uncle.Gender = 'male';
            }
            if (familyMembers.Aunt?.Name && !familyMembers.Aunt.Gender) {
                familyMembers.Aunt.Gender = 'female';
            }
            if (familyMembers.Mother?.Name && !familyMembers.Mother.Gender) {
                familyMembers.Mother.Gender = 'female';
            }
            if (familyMembers.Father?.Name && !familyMembers.Father.Gender) {
                familyMembers.Father.Gender = 'male';
            }
            if (familyMembers.Spouse?.Name && !familyMembers.Spouse.Gender) {
                familyMembers.Spouse.Gender = data.Gender === 'Male' ? 'female' : 'male';
            }

            // Clean up empty children entries
            if (familyMembers.Childrens) {
                familyMembers.Childrens = familyMembers.Childrens.filter(child => child && child.Name);
            }

            if (!user) {
                const response = await request('honoree.create', data);
                history.push(`/honoree/${response.Alias || response._id}`);
            } else {
                const response = await request('honoree.update', data);
                update(response);
            }
        } catch (error) {
            handleError(error);
        }
    };

    useEffect(() => {
        if (user?.FamilyMembers?.Childrens?.length) {
            setInputFields(user.FamilyMembers.Childrens);
        }
    }, [user]);

    return (
        <div className="central-meta">
            <div className="editing-info">
                <Form
                    onSubmit={onSubmit}
                    initialValues={{
                        Gender: 'Male',
                        ...user,
                        DOB: user ? formatDate(user.DOB) : '',
                        DOD: user ? formatDate(user.DOD) : '',
                    }}
                    render={({ handleSubmit, submitting, values, form }) => {
                        const handleRemoveChild = (index) => {
                            // Update local state
                            removeInputFields(index)

                            // Update form values
                            const currentChildren = values.FamilyMembers?.Childrens || [];
                            const updatedChildren = currentChildren.filter((_, idx) => idx !== index);

                            form.mutators.setValue('FamilyMembers.Childrens', updatedChildren);
                        };

                        return (
                            <form onSubmit={handleSubmit} ref={infoRef}>
                                <BasicInfoSection
                                    t={t}
                                    readOnly={readOnly}
                                    values={values}
                                />

                                <CityStateCountry
                                    values={values}
                                    readOnly={readOnly}
                                    cityProp="POB"
                                    stateProp="SOB"
                                    countryProp="COB"
                                    placeTitle={t('honoree.placeOfBirth')}
                                />

                                <CityStateCountry
                                    values={values}
                                    readOnly={readOnly}
                                    cityProp="POD"
                                    stateProp="SOD"
                                    countryProp="COD"
                                    placeTitle={t('honoree.placeOfDeath')}
                                />

                                <Field component={TextField}
                                    name="FuneralHomeSite"
                                    label={t('honoree.funeralHomeWebsite')}
                                    readOnly={readOnly}
                                />

                                <Field component={TextField}
                                    name="CemeteryAddress"
                                    label={t('honoree.cemeteryAddress')}
                                    readOnly={readOnly}
                                />

                                <Field component={TextArea}
                                    name="About"
                                    label={t('honoree.aboutHonoree')}
                                    readOnly={readOnly}
                                />

                                <SocialInfoSection
                                    t={t}
                                    readOnly={readOnly}
                                />

                                <FamilyMembersSection
                                    t={t}
                                    readOnly={readOnly}
                                    values={values}
                                    form={form}
                                />

                                <div className='form-row text-center'>
                                    <div className="submit-btns text-center" style={{ width: '100%' }}>
                                        {!readOnly && (
                                            <LoadingButton
                                                loading={submitting}
                                                title={t('Save All')}
                                            />
                                        )}
                                        {values?.FamilyMembers && (
                                            <FamilyTree
                                                honoree={values}
                                                update={update}
                                                editMode={!!user && !readOnly}
                                                readOnly={readOnly}
                                            />
                                        )}
                                    </div>
                                </div>
                            </form>
                        )
                    }}
                    mutators={{
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value);
                        }
                    }}
                />

                <Gallery
                    galleryType="BasicInfo"
                    user={user}
                    readOnly={readOnly}
                />
            </div>
        </div>
    );
}