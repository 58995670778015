import React from 'react';
import { Field } from 'react-final-form';
import TextField from '../../../Components/Form/TextField';
import DateField from '../../../Components/Form/DateField';
import RadioGroup from '../../../Components/Form/RadioGroup';
import { required, validateAlphanumeric } from '../../../Util/form';

const BasicInfoSection = ({ t, readOnly, values }) => (
  <>
    <h5 className="f-title">
      <i className="ti-user" /> {t('honoree.personalInformation')}
    </h5>

    <Field component={RadioGroup}
      name="Gender"
      options={[
        { name: t('honoree.mr'), value: 'Male' }, 
        { name: t('honoree.mrs'), value: 'Female' }
      ]}
      validate={required}
      required
      readOnly={readOnly}
    />

    <div className="form-row">
      <div className="col">
        <Field component={TextField}
          name="FirstName"
          label={t('fields.firstName')}
          validate={required}
          required
          readOnly={readOnly}
        />
      </div>
      <div className="col">
        <Field component={TextField}
          name="MiddleName"
          label={t('honoree.middleName')}
          readOnly={readOnly}
        />
      </div>
      <div className="col">
        <Field component={TextField}
          name="LastName"
          label={t('fields.lastName')}
          validate={required}
          required
          readOnly={readOnly}
        />
      </div>
    </div>

    {!readOnly && (
      <div className="form-row">
        <div className="col">
          <Field component={TextField}
            name="Nickname"
            label={t('honoree.nickname')}
            readOnly={readOnly}
          />
        </div>
        <div className='col'>
          <Field component={TextField}
            name="Alias"
            label={t('honoree.customizeURL')}
            readOnly={readOnly}
            validate={validateAlphanumeric}
            prefixText={`${window.location.origin}/honoree/`}
          />
        </div>
      </div>
    )}

    <div className="form-row">
      <div className="col">
        <Field component={DateField}
          name="DOB"
          label={t('fields.dob')}
          type="text"
          validate={required}
          required
          readOnly={readOnly}
        />
      </div>
      <div className="col">
        <Field component={DateField}
          name="DOD"
          label={t('honoree.dateOfDeath')}
          type="text"
          validate={required}
          required
          readOnly={readOnly}
        />
      </div>
    </div>
  </>
);

export default BasicInfoSection;