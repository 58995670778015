import React from 'react';

const FamilyMember = ({ member, relationshipType }) => {
    return (
        <div className="family-member-card">
            <div className="member-icon">
                <i className={`ti-user icon-${member?.Gender?.toLowerCase() || 'male'}`}></i>
            </div>
            <div className="member-info">
                <h4>{member?.Name}</h4>
                <p className="relationship">{relationshipType}</p>
            </div>
        </div>
    );
};

export default FamilyMember;
