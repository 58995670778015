import React from 'react';
import Layout from '../../Components/Layout';

// import PersonalInfo from './BasicInfo';
import PersonalInfo from './PersonalInfo';
import PersonalInfoPet from './BasicInfoPet';
import FamilyTree from './List';

import { useTranslation } from "react-i18next";
import { Translate } from 'react-auto-translate/lib/commonjs';

export default function About() {
  const {t} = useTranslation('common');
  const menuConfig = [
    {
      // title: t('honoree.myHonoreeProfiles'),
      title: <Translate>Honoree Profiles</Translate>,
      contents: FamilyTree,
      iconCls: 'fa fa-list',
      path: ''
    }, {
      title: <Translate>Create honoree profile</Translate>,
      contents: PersonalInfo,
      iconCls: 'fa fa-user',
      path: 'create'
    }, {
      title: <Translate>Create pet profile</Translate>,
      contents: PersonalInfoPet,
      iconCls: 'fa fa-paw',
      path: 'create-pet'
    }
  ];

  return(
    <Layout config={menuConfig} title={t('honoree.actions')} />
  );
};
